import reminderService from './services/reminderService';

/*must be synchronized with bootstrap breakpoints*/
import { format } from 'date-fns';
import i18n from 'i18next';
import { Master } from './interfaces/master';
import { MASTER_TYPE, REGEX, USER_LEVEL } from './constants';
import { GenericObject } from './interfaces/generic-object';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 950,
  xl: 1200,
  xxl: 1400,
};

const dataIntegrationRequiredFields = (
  userType: string = USER_LEVEL.STANDARD,
  masterType: string = MASTER_TYPE.B2B,
): { [key: string]: string[] } => {
  if (userType === USER_LEVEL.LIGHT && masterType === MASTER_TYPE.B2C) {
    return {
      tiers: [
        'ref',
        'addressLine1',
        'city',
        'zipCode',
        'firstName',
        'lastName',
        'phone',
        'email',
      ],
      movements: ['tiersRef', 'documentNumber', 'documentDate', 'dueDate'],
    };
  } else {
    return {
      tiers: [
        'ref',
        'legalId',
        'name',
        'addressLine1',
        'city',
        'zipCode',
        'phone',
        'email',
      ],
      movements: ['tiersRef', 'documentNumber', 'documentDate', 'dueDate'],
    };
  }
};

const dataIntegrationMapping = (
  userType: string = USER_LEVEL.STANDARD,
  masterType: string = MASTER_TYPE.B2B,
): { [key: string]: string[] } => {
  if (userType === USER_LEVEL.LIGHT) {
    if (masterType === MASTER_TYPE.B2C) {
      return {
        tiers: [
          'ref',
          'firstName',
          'lastName',
          'phone',
          'email',
          'addressLine1',
          'addressLine2',
          'addressLine3',
          'city',
          'zipCode',
          'countryIso3',
        ],
        movements: [
          'tiersRef',
          'documentNumber',
          'type',
          'label',
          'rootAccount',
          'documentDate',
          'dueDate',
          'debit',
          'credit',
          'amount',
        ],
      };
    } else {
      return {
        tiers: [
          'ref',
          'legalId',
          'name',
          'phone',
          'email',
          'addressLine1',
          'addressLine2',
          'addressLine3',
          'city',
          'zipCode',
          'countryIso3',
        ],
        movements: [
          'tiersRef',
          'documentNumber',
          'type',
          'label',
          'rootAccount',
          'documentDate',
          'dueDate',
          'debit',
          'credit',
          'amount',
        ],
      };
    }
  }
  return {
    tiers: [
      'ref',
      'legalId',
      'name',
      'type',
      'phone',
      'email',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'city',
      'zipCode',
      'countryIso3',
    ],
    movements: [
      'tiersRef',
      'documentNumber',
      'type',
      'label',
      'rootAccount',
      'documentDate',
      'dueDate',
      'debit',
      'credit',
      'amount',
    ],
  };
};

const dateFormats: string[] = [
  'dd/MM/yyyy',
  'yyyy-MM-dd',
  'MM-dd-yyyy',
  'yyyyMMdd',
];

const isNil = (value: any) => {
  return value === null || value === undefined;
};

const isNilOrEmptyString = (value: any) => {
  return value === '' || value === null || value === undefined;
};

const isNilOrEmpty = (value: any) => {
  if (typeof value === 'object') {
    return isNil(value) || !Object.keys(value).length;
  }
  return isNil(value) || !value.length;
};

const getBestDateFormat = (dateToTest: any) => {
  const dateRegex = REGEX.DATE;
  const dateFormat = REGEX.FORMAT;
  let foundFormat: string = 'yyyyMMdd';
  Object.values(dateRegex).forEach((value: any, index: number) => {
    if (value.test(dateToTest)) {
      foundFormat = dateFormat[Object.keys(dateRegex)[index]];
    }
  });
  return foundFormat;
};

const getToday = () => {
  return format(new Date(), 'yyyy-MM-dd').toString();
};

const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  return tomorrow;
};

const getMailToUrl = async ({
  fileUrl,
  recipient,
  emailClient,
}: {
  fileUrl: string;
  recipient: string;
  emailClient: string;
}): Promise<string> => {
  const data = await reminderService.getFileReminder({ url: fileUrl });
  const res = await data;
  const mailContent: string = new TextDecoder().decode(res.data);

  let urlStart: string = 'mailto:';
  let urlSubjectPart: string = '?subject=';
  const urlBodyPart: string = '&body=';

  if (emailClient === 'gmail') {
    urlStart = 'https://mail.google.com/mail/?view=cm&fs=1&to=';
    urlSubjectPart = '&su=';
  }

  const link: string = `${urlStart}${recipient}${urlSubjectPart}${getSubjectEmailReminder(
    mailContent,
  )}${urlBodyPart}${getBodyEmailReminder(mailContent)}`;
  return link.replaceAll('\n', '%0D%0A');
};

const getLanguage = () => {
  return i18n.language?.includes('fr') ? 'FR' : 'EN';
};

const getLocale = () => {
  return i18n.language?.includes('fr') ? 'fr-FR' : 'en-US';
};
const combineTwoArrays = (
  baseArray: any[],
  arrayToCombine: any[] | undefined,
  key: string,
  ids: any,
) => {
  const final = [...baseArray];
  if (!arrayToCombine) return final;
  arrayToCombine.forEach((element: any, index: number) => {
    const ref = arrayToCombine[index][key];
    const foundKey = baseArray.find((o: any) => o[key] === ref);
    if (!foundKey) {
      final.push(element);
    }
  });

  return final.filter((el: any) => {
    return ids[el[key]];
  });
};
const capitalize = (value?: string) => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
};

const getSubjectEmailReminder = (mailContent: string): string => {
  let subject = mailContent.split('SUBJECT:')[1];
  subject = subject?.split('BODY:')[0];
  return subject?.trim();
};

const getSignUrl = (signMethod: string, docusignDocId: any, fileId: any) => {
  return signMethod === 'PUT'
    ? `?envelope-id=${docusignDocId}`
    : (fileId as string);
};

const getBodyEmailReminder = (mailContent: string): string => {
  const body = mailContent?.split('BODY:')[1];
  return body?.trim();
};
const objToString = (obj: any): string => {
  let str = '';
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str += `${p}:${obj[p]}`;
    }
  }
  return str;
};

const multiObjToString = (arr: any[] | undefined): string => {
  if (!arr) return '';
  const str: string[] = [];
  arr.forEach((obj) => {
    str.push(`ids=${obj.type}:${obj.value}`);
  });
  return str.join('&');
};

const addMasterRefProps = (master: Master | undefined) => {
  if (!master) return null;
  return {
    masterRef: master.reference,
    masterCurrency: master.settings.accountingCurrency,
    masterAddress: master.address,
  };
};

const dataIntegrationErrorFormatter = (errors: any[]) => {
  if (!errors) return [];
  const results: any[] = [];
  errors.forEach((row: any) => {
    row.errors.forEach((col: any) => {
      results.push({ row: row.rowNumber, col: col.colNumber });
    });
  });
  return results;
};

const dataIntegrationReformatErrors = (errorsData: any) => {
  interface Error {
    colNumber: number;
    error: string;
    error_description: string;
    code: string;
  }

  interface RowError {
    rowNumber: number;
    errors: Error[];
  }

  if (!errorsData) return [];
  return errorsData.reduce((acc: any, row: RowError) => {
    row.errors.forEach((error) => {
      const colNumber: number = error.colNumber;
      acc[colNumber] = acc[colNumber] || [];
      acc[colNumber].message =
        acc[colNumber].message || error.error_description;
      acc[colNumber].code = acc[colNumber].code || error.error;
      acc[colNumber].colNumber = acc[colNumber].colNumber || colNumber;
      acc[colNumber].lines = acc[colNumber].lines || [];
      acc[colNumber].lines.push({ line: row.rowNumber });
    });
    return acc;
  }, {});
};

const transformReminder = (reminder: any) => {
  return {
    comment: reminder.comment,
    createdDate: reminder.date,
    endComment: reminder.detail.endComment,
    endDate: reminder.detail.endDate,
    id: reminder.detail.id,
    url: reminder.detail.url,
    title: reminder.title,
  };
};

const getFileUrlFromArrayBuffer = (
  bytes: ArrayBuffer,
  blobType: string = 'application/pdf',
): string => {
  const bytesArray = new Uint8Array(bytes);
  const blob = new Blob([bytesArray], { type: blobType });
  return URL.createObjectURL(blob);
};

const getFileLoad = (bytes: ArrayBuffer, fileName: string): any => {
  const bytesArray = new Uint8Array(bytes);
  const blob = new Blob([bytesArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

const getEmailFromTiers = (tiers: any | undefined): string => {
  if (tiers?.collectionContact && tiers?.collectionContact?.email) {
    return tiers.collectionContact?.email;
  }
  if (tiers?.email) {
    return tiers.email;
  }

  return '';
};

const getKeyByValue = (object: any, value: string) => {
  const values = Object.values(object).filter((item) => item === value);
  if (values.length !== 1) return void 0;

  return Object.keys(object).find((key) => object[key] === value);
};

const getArraysIntersection = ({
  arr1,
  arr2,
}: {
  arr1: string[];
  arr2: string[];
}): string[] => {
  const setFromArray = new Set(arr2);
  return Array.from(new Set(arr1)).filter((item) => setFromArray.has(item));
};

const getArraysDifference = ({
  arr1,
  arr2,
}: {
  arr1: string[];
  arr2: string[];
}): string[] => {
  const setFromArray = new Set(arr2);
  return Array.from(new Set(arr1)).filter((x) => !setFromArray.has(x));
};

const arraysHasDuplicateEntries = (array: string[]): boolean => {
  const setFromArray = new Set(array);
  return Array.from(setFromArray).length !== array.length;
};

const getDuplicatesFromArray = (array: string[]): string[] => {
  const dupicates: string[] = array.filter(
    (item: string, index: number) => array.indexOf(item) !== index,
  );

  return Array.from(new Set(dupicates));
};

const getDuplicatesHeader = (array: string[]): string[] => {
  const counts: any = {};
  return array.map((u: string) => {
    if (counts[u]) {
      counts[u] += 1;
      u += ` (${counts[u]})`;
    } else if (array.filter((a: string) => a === u).length > 1) {
      counts[u] = 1;
      u += ` (${counts[u]})`;
    }
    return u;
  });
};

const removeKeysWithEmptyValueFromObject = (
  obj: {
    [key: string]: any;
  },
  uploadType: any,
  requiredFields?: GenericObject<string[]>,
): { [key: string]: any } => {
  if (requiredFields) {
    return Object.keys(obj)
      .filter(
        (k) =>
          obj[k] !== '' && requiredFields.uploadType?.indexOf(obj[k]) !== -1,
      )
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  } else {
    return Object.keys(obj)
      .filter((k) => obj[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
  }
};

const objectsHaveSameKeys = (...objects: any[]): boolean => {
  const allKeys = objects.reduce(
    (keys, object) => keys.concat(Object.keys(object)),
    [],
  );
  const union = new Set(allKeys);
  return objects.every((object) => union.size === Object.keys(object).length);
};

const convertBytesToKB = (
  bytes: number,
  unit: boolean = true,
): number | string => {
  const round: number = Math.round(bytes / 1024);
  const unitLang = getLanguage() === 'FR' ? 'Ko' : 'Kb';
  return unit ? `${round} ${unitLang}` : round;
};

const windowScroll = () => {
  return window.scrollTo({
    top: document.body.scrollHeight,
    left: 0,
    behavior: 'smooth',
  });
};

const getBaseUrl = (url: URL) => {
  let baseUrl = `${url.protocol}//${url.hostname}`;
  if (url.port) {
    baseUrl += `:${url.port}`;
  }
  return baseUrl;
};

const convertBytesToMb = (bytes: number) => {
  const round = bytes / Math.pow(1024, 2);
  return parseFloat(round.toString()).toFixed(round > 1 ? 0 : 1);
};

const checkValuePositive = (someArray: any) => {
  return someArray.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    0,
  );
};

const valueOrEmptyString = (value: any) => value || '';

const areArraysEqual = (array1: any[], array2: any[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
      return false;
    }
  }

  return true;
};

const getInitialsFromName = (name: string) => {
  if (!name) return '';
  const formattedName = name?.replaceAll('-', ' ').replaceAll('_', ' ');
  const splittedName = formattedName.split(' ');
  let initials = '';
  splittedName.forEach((n) => {
    if (initials.length < 2) {
      initials += n.slice(0, 1);
    }
  });
  return initials.toUpperCase();
};

const numberFormatter = (value: number) => {
  return new Intl.NumberFormat(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const getClassRed = (value: boolean) => {
  return value ? 'red' : '';
};

const scrollWithOffset = (el: HTMLElement, yOffset: number) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  window.scrollBy({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const replaceNbsps = (str?: string) => {
  if (!str) return '';
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(re, ' ');
};

const utils = {
  breakpoints,
  capitalize,
  dataIntegrationRequiredFields,
  dataIntegrationMapping,
  dateFormats,
  dataIntegrationErrorFormatter,
  dataIntegrationReformatErrors,
  transformReminder,
  getMailToUrl,
  isNil,
  isNilOrEmptyString,
  isNilOrEmpty,
  getBestDateFormat,
  objToString,
  multiObjToString,
  getLanguage,
  getLocale,
  getSubjectEmailReminder,
  getBodyEmailReminder,
  addMasterRefProps,
  getFileUrlFromArrayBuffer,
  getFileLoad,
  getKeyByValue,
  getEmailFromTiers,
  getSignUrl,
  getArraysIntersection,
  getArraysDifference,
  getToday,
  getTomorrow,
  arraysHasDuplicateEntries,
  getDuplicatesFromArray,
  removeKeysWithEmptyValueFromObject,
  objectsHaveSameKeys,
  getDuplicatesHeader,
  convertBytesToKB,
  windowScroll,
  getBaseUrl,
  convertBytesToMb,
  valueOrEmptyString,
  combineTwoArrays,
  checkValuePositive,
  areArraysEqual,
  getInitialsFromName,
  numberFormatter,
  getClassRed,
  scrollWithOffset,
  replaceNbsps,
};

export default utils;
