import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../setupStore';

const initialState = {
  value: false,
};

export const CasesReloadAfterDataIntegSlice = createSlice({
  name: 'casesReloadAfterDataInteg',
  initialState,
  reducers: {
    casesReloadAfterDataInteg: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { casesReloadAfterDataInteg } =
  CasesReloadAfterDataIntegSlice.actions;

export const selectCasesReloadAfterDataInteg = (state: RootState) =>
  state.casesReloadAfterDataInteg.value;

export default CasesReloadAfterDataIntegSlice.reducer;
