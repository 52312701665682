import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { meApi } from '../services/meApi';
import { publicApi } from '../services/publicApi';
import { reminderApi } from '../services/reminderApi';
import keycloackConfigReducer from './slices/keycloackConfig/keyCloakConfigSlice';
import tenantIdReducer from './slices/tenantId/tenantIdSlice';
import tiersReducer from './slices/tiers/tiersSlice';
import tokenReducer from './slices/token/tokenSlice';
import visibilityReducer from './slices/visibility/visibilitySlice';
import invoiceEntryReducer from './slices/invoiceEntry/invoiceEntrySlice';
import showErrorMappingReducer from './slices/showErrorMapping/showErrorMappingSlice';
import replanNotificationMappingReducer from './slices/playReplanNotification/playReplanNotificationSlice';
import paramsReplanNotificationReducer from './slices/playReplanNotification/paramsReplanNotificationSlice';
import resultReplanNotificationReducer from './slices/playReplanNotification/resultReplanNotificationSlice';
import resultMappingHeaderNotificationReducer from './slices/dataIntegration/mappingHeaderSlice';
import dataIntegrationSubmitReducer from './slices/dataIntegration/dataIntegrationSubmitSlice';
import dataIntegrationFailReducer from './slices/dataIntegration/dataIntegrationFailSlice';
import contractEventReducer from './slices/contractEvent/contractEventSlice';
import lastDateReloadMovementReducer from './slices/lastDateIncentiveReloadMouvement/lastDateIncentiveReloadMouvementSlice';
import rowSelectReducer from './slices/selectRow/selectRowSlice';
import { caseApi } from '../services/caseApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { CasesReloadAfterDataIntegSlice } from './slices/dataIntegration/casesReloadAfterDataIntegSlice';

// Create the root reducer separately so we can extract the RootState type
export const rootReducer = combineReducers({
  contractEvent: contractEventReducer,
  token: tokenReducer,
  tenantId: tenantIdReducer,
  tiers: tiersReducer,
  keycloackConfig: keycloackConfigReducer,
  visibility: visibilityReducer,
  invoiceEntry: invoiceEntryReducer,
  showErrorMapping: showErrorMappingReducer,
  lastDateReloadMovement: lastDateReloadMovementReducer,
  replanNotificationMapping: replanNotificationMappingReducer,
  paramsReplanNotification: paramsReplanNotificationReducer,
  resultReplanNotification: resultReplanNotificationReducer,
  resultMappingHeaderNotification: resultMappingHeaderNotificationReducer,
  rowSelect: rowSelectReducer,
  detectDataIntegrationSubmit: dataIntegrationSubmitReducer,
  detectDataIntegrationFail: dataIntegrationFailReducer,
  casesReloadAfterDataInteg: CasesReloadAfterDataIntegSlice.reducer,
  [reminderApi.reducerPath]: reminderApi.reducer,
  [meApi.reducerPath]: meApi.reducer,
  [caseApi.reducerPath]: caseApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
});

// Store for app
export const _setupStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      reminderApi.middleware,
      meApi.middleware,
      caseApi.middleware,
      publicApi.middleware,
    ),
});

// Store for tests
export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        reminderApi.middleware,
        meApi.middleware,
        caseApi.middleware,
        publicApi.middleware,
      ),
    preloadedState,
  });
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

setupListeners(_setupStore.dispatch);
